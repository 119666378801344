
export function CopyIcon({iconClass}){
    return(
<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
 <g>
  <path d="m1090.3 183.98v590.81c0.03125 19.555-7.7109 38.32-21.52 52.168s-32.555 21.637-52.109 21.66h-97.465v-438.25c0-71.133-57.75-128.99-128.73-128.99h-438.46v-97.406c0.015625-19.543 7.793-38.281 21.625-52.086 13.828-13.809 32.578-21.559 52.121-21.543h590.8c19.539 0 38.277 7.7539 52.102 21.559 13.824 13.805 21.605 32.531 21.633 52.07z"/>
  <path d="m790.27 351.7h-621.09c-15.539 0-30.445 6.1719-41.434 17.16s-17.16 25.895-17.16 41.434v621.09c0 15.539 6.1719 30.445 17.16 41.434s25.895 17.16 41.434 17.16h621.09c15.539 0 30.445-6.1719 41.434-17.16s17.16-25.895 17.16-41.434v-621.09c0-15.539-6.1719-30.445-17.16-41.434s-25.895-17.16-41.434-17.16zm-155.25 509.77h-310.97c-18.527 0-34.418-14.062-35.754-32.531-0.73047-9.7539 2.6367-19.371 9.2891-26.539 6.6523-7.1719 15.992-11.242 25.773-11.242h310.96c18.527 0 34.418 14.062 35.754 32.531 0.73047 9.7539-2.6367 19.371-9.2891 26.539-6.6523 7.1719-15.992 11.242-25.773 11.242zm0-210.94h-310.97c-18.527 0-34.418-14.062-35.754-32.531-0.73047-9.7539 2.6367-19.371 9.2891-26.539 6.6523-7.1719 15.992-11.242 25.773-11.242h310.96c18.527 0 34.418 14.062 35.754 32.531 0.73047 9.7539-2.6367 19.371-9.2891 26.539-6.6523 7.1719-15.992 11.242-25.773 11.242z"/>
 </g>
</svg>
    )
}

export function Bold({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
    <path d="m741.72 570.48c28.402-38.238 41.574-85.668 36.961-133.07-4.6133-47.406-26.68-91.406-61.918-123.45s-81.133-49.852-128.76-49.953h-240v672h312c43.562-0.10547 85.793-15.023 119.76-42.301 33.965-27.277 57.645-65.293 67.148-107.81 9.5078-42.512 4.2695-86.992-14.844-126.14-19.113-39.145-50.973-70.625-90.344-89.273zm-297.72-210.48h144c34.297 0 65.988 18.297 83.137 48 17.152 29.703 17.152 66.297 0 96-17.148 29.703-48.84 48-83.137 48h-144zm216 480h-216v-192h216c34.297 0 65.988 18.297 83.137 48 17.152 29.703 17.152 66.297 0 96-17.148 29.703-48.84 48-83.137 48z"/>
   </svg>
   );
}

export function Italic({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
     <path d="m792 348v-96h-288v96h91.078l-87.598 504h-99.48v96h288v-96h-91.078l87.598-504z"/>
   </svg>
   );
}

export function IUnderline({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
     <g>
  <path d="m600 792c42.129 0 83.516-11.09 120-32.152 36.484-21.066 66.781-51.363 87.848-87.848 21.062-36.484 32.152-77.871 32.152-120v-312h-96v312c0 51.445-27.445 98.984-72 124.71-44.555 25.723-99.445 25.723-144 0-44.555-25.723-72-73.262-72-124.71v-312h-96v312c0 42.129 11.09 83.516 32.152 120 21.066 36.484 51.363 66.781 87.848 87.848 36.484 21.062 77.871 32.152 120 32.152z"/>
  <path d="m288 864h624v96h-624z"/>
 </g>
    </svg>
   );
}

export function Strikethrough({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
    <g>
  <path d="m408 360h144v120h96v-120h144v72h96v-120c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059h-480c-12.73 0-24.938 5.0586-33.941 14.059-9 9.0039-14.059 21.211-14.059 33.941v120h96z"/>
  <path d="m648 720h-96v120h-96v96h288v-96h-96z"/>
  <path d="m240 552h720v96h-720z"/>
 </g>
    </svg>
   );
}

export function Paragraph({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
     <path d="m804 264h-276c-54.105 0-105.99 21.492-144.25 59.75s-59.75 90.145-59.75 144.25 21.492 105.99 59.75 144.25 90.145 59.75 144.25 59.75h60v264h96v-576h96v576h96v-600c0-19.094-7.5859-37.41-21.09-50.91-13.5-13.504-31.816-21.09-50.91-21.09zm-216 312h-60c-38.586 0-74.238-20.586-93.531-54s-19.293-74.586 0-108 54.945-54 93.531-54h60z"/>
    </svg>
   );
}

export function TextSize(){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className="editorIcon">
     <g>
  <path d="m564 360h144v72h96v-120c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059h-480c-12.73 0-24.938 5.0586-33.941 14.059-9 9.0039-14.059 21.211-14.059 33.941v120h96v-72h144v480h-96v96h288v-96h-96z"/>
  <path d="m888 840c-6.3633 0-12.469-2.5273-16.969-7.0312-4.5039-4.5-7.0312-10.605-7.0312-16.969v-108h96v-96h-96v-96h-96v96h-96v96h96v108c0 31.824 12.645 62.348 35.148 84.852 22.504 22.504 53.027 35.148 84.852 35.148h84v-96z"/>
 </g>
    </svg>
   );
}


export function Blockquote({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
        <g>
     <path d="m888 552h-144c0-38.191 15.172-74.816 42.176-101.82 27.008-27.004 63.633-42.176 101.82-42.176h48v-96h-48c-63.652 0-124.7 25.285-169.71 70.293-45.008 45.012-70.293 106.05-70.293 169.71v240c0 25.461 10.113 49.879 28.117 67.883 18.004 18.004 42.422 28.117 67.883 28.117h144c25.461 0 49.879-10.113 67.883-28.117 18.004-18.004 28.117-42.422 28.117-67.883v-144c0-25.461-10.113-49.879-28.117-67.883-18.004-18.004-42.422-28.117-67.883-28.117zm0 240h-144v-144h144z"/>
  <path d="m456 552h-144c0-38.191 15.172-74.816 42.176-101.82 27.008-27.004 63.633-42.176 101.82-42.176h48v-96h-48c-63.652 0-124.7 25.285-169.71 70.293-45.008 45.012-70.293 106.05-70.293 169.71v240c0 25.461 10.113 49.879 28.117 67.883 18.004 18.004 42.422 28.117 67.883 28.117h144c25.461 0 49.879-10.113 67.883-28.117 18.004-18.004 28.117-42.422 28.117-67.883v-144c0-25.461-10.113-49.879-28.117-67.883-18.004-18.004-42.422-28.117-67.883-28.117zm0 240h-144v-144h144z"/>
 </g>
    </svg>
   );
}

export function Undo({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
         <path d="m600 408c-54.496 0.023438-108.36 11.648-158.02 34.094-49.656 22.449-93.965 55.211-129.98 96.105v-130.2h-96v240c0 12.73 5.0586 24.938 14.059 33.941 9.0039 9 21.211 14.059 33.941 14.059h240v-96h-118.56c38.891-43.438 90.051-74.047 146.71-87.781s116.16-9.9453 170.62 10.859c54.465 20.809 101.33 57.66 134.41 105.68 33.07 48.016 50.789 104.94 50.812 163.25h96c0-67.406-17.742-133.62-51.445-192-33.703-58.375-82.18-106.85-140.55-140.55-58.375-33.703-124.59-51.445-192-51.445z"/>
    </svg>
   );
}

export function BulletList({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
         <g>
  <path d="m444 264h504v96h-504z"/>
  <path d="m444 456h504v96h-504z"/>
  <path d="m348 504c0 26.508-21.492 48-48 48s-48-21.492-48-48 21.492-48 48-48 48 21.492 48 48"/>
  <path d="m348 312c0 26.508-21.492 48-48 48s-48-21.492-48-48 21.492-48 48-48 48 21.492 48 48"/>
  <path d="m444 648h504v96h-504z"/>
  <path d="m348 696c0 26.508-21.492 48-48 48s-48-21.492-48-48 21.492-48 48-48 48 21.492 48 48"/>
  <path d="m444 840h504v96h-504z"/>
  <path d="m348 888c0 26.508-21.492 48-48 48s-48-21.492-48-48 21.492-48 48-48 48 21.492 48 48"/>
 </g>
    </svg>
   );
}

export function NumList({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
          <g>
  <path d="m300 552h96v-252c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059h-120v96h72z"/>
  <path d="m504 264h468v96h-468z"/>
  <path d="m504 456h468v96h-468z"/>
  <path d="m504 648h468v96h-468z"/>
  <path d="m504 840h468v96h-468z"/>
  <path d="m352.32 831.48 41.16-41.16c16.902-16.977 26.383-39.961 26.363-63.918-0.023438-23.957-9.543-46.926-26.473-63.875-16.934-16.945-39.895-26.484-63.852-26.527h-101.52v96h88.078l-31.68 31.68c-17.922 17.883-32.133 39.133-41.812 62.527-9.6797 23.395-14.637 48.473-14.586 73.793 0 12.73 5.0586 24.938 14.059 33.941 9.0039 9 21.211 14.059 33.941 14.059h156v-96h-95.281c4.2695-7.5 9.5156-14.402 15.602-20.52z"/>
 </g>
    </svg>
   );
}

export function CodeBlock({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
           <g>
  <path d="m463.92 558-132-132 132-132-67.922-67.922-165.96 165.96c-9.0117 9.0039-14.078 21.223-14.078 33.961s5.0664 24.957 14.078 33.961l165.96 165.96z"/>
  <path d="m969.96 740.04-165.96-165.96-67.922 67.922 132 132-132 132 67.922 67.922 165.96-165.96c9.0117-9.0039 14.078-21.223 14.078-33.961s-5.0664-24.957-14.078-33.961z"/>
  <path d="m274.05 852 583.87-583.87 67.883 67.883-583.87 583.87z"/>
 </g>
    </svg>
   );
}

export function Note({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
           <g>
  <path d="m372 876h69.48l62.52 65.641c13.414 14.383 29.789 25.684 48 33.121 23.953 9.5234 50.176 11.828 75.422 6.625 25.25-5.2031 48.422-17.684 66.656-35.906l69.844-69.48h64.078c38.191 0 74.816-15.172 101.82-42.176 27.004-27.008 42.176-63.633 42.176-101.82v-372c0-38.191-15.172-74.816-42.176-101.82-27.008-27.004-63.633-42.176-101.82-42.176h-456c-38.191 0-74.816 15.172-101.82 42.176-27.004 27.008-42.176 63.633-42.176 101.82v372c0 38.191 15.172 74.816 42.176 101.82 27.008 27.004 63.633 42.176 101.82 42.176zm-48-364.44v-151.56c0-12.73 5.0586-24.938 14.059-33.941 9.0039-9 21.211-14.059 33.941-14.059h456c12.73 0 24.938 5.0586 33.941 14.059 9 9.0039 14.059 21.211 14.059 33.941v372c0 12.73-5.0586 24.938-14.059 33.941-9.0039 9-21.211 14.059-33.941 14.059h-64.078c-25.469-0.019531-49.902 10.082-67.922 28.078l-70.32 70.32c-6.5 6.6211-15.383 10.348-24.66 10.348-9.2734 0-18.16-3.7266-24.66-10.348l-65.52-68.398c-18.047-19.062-43.109-29.902-69.359-30h-69.48c-12.73 0-24.938-5.0586-33.941-14.059-9-9.0039-14.059-21.211-14.059-33.941z"/>
  <path d="m492 600h60v60c0 17.148 9.1484 32.996 24 41.57 14.852 8.5742 33.148 8.5742 48 0 14.852-8.5742 24-24.422 24-41.57v-60h60c17.148 0 32.996-9.1484 41.57-24 8.5742-14.852 8.5742-33.148 0-48-8.5742-14.852-24.422-24-41.57-24h-60v-60c0-17.148-9.1484-32.996-24-41.57-14.852-8.5742-33.148-8.5742-48 0-14.852 8.5742-24 24.422-24 41.57v60h-60c-17.148 0-32.996 9.1484-41.57 24-8.5742 14.852-8.5742 33.148 0 48 8.5742 14.852 24.422 24 41.57 24z"/>
 </g>
    </svg>
   );
}

export function Clear({iconClass}){
    return(<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
           <path d="m1107.6 259.2h-776.4c-15.602 0-31.199 7.1992-40.801 20.398l-224.4 310.8c-8.3984 12-8.3984 27.602 0 39.602l224.4 291.6c9.6016 12 24 19.199 39.602 19.199h777.6c18 0 32.398-14.398 32.398-32.398v-616.8c0-18-14.398-32.402-32.398-32.402zm-271.2 435.6-72 72-94.797-94.801-94.801 94.801-72-72 94.801-94.801-94.801-94.801 72-72 94.801 94.801 94.801-94.801 72 72-94.801 94.801z"/>
    </svg>
   );
}

export function Trash({iconClass}){
    return(
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
 <path d="m468.99 58.367v37.887c0 3.5859-3.0703 6.6562-6.6562 6.6562h-412.67c-3.5859 0-6.6562-3.0703-6.6562-6.6562v-37.887c0-3.5859 3.0703-6.6562 6.6562-6.6562h165.89v-29.184c0-4.6094 3.5859-7.6797 7.6797-7.6797h68.609c4.6094 0 7.6797 3.5859 7.6797 7.6797v29.184h162.3c4.0977 0 7.168 3.0742 7.168 6.6562zm-19.969 81.41v37.887c0 3.5859-3.0703 6.6562-6.6562 6.6562h-15.871l-22.527 266.75c-1.5352 25.602-23.039 45.566-49.152 45.566h-191.49c-25.602 0-46.594-19.457-49.152-45.055l-28.16-267.78h-16.383c-3.5859 0-6.6562-3.0703-6.6562-6.6562v-37.887c0-3.5859 3.0703-6.6562 6.6562-6.6562h372.22c4.0977 0.51172 7.168 3.582 7.168 7.168zm-246.27 300.03-7.168-232.96c0-4.6094-4.0977-8.7031-8.7031-8.7031h-32.258c-5.1211 0-9.2148 4.0977-8.7031 9.2148l13.82 232.96c0.51172 4.6094 4.0977 8.1914 8.7031 8.1914h25.602c5.1211 0.51172 8.707-3.582 8.707-8.7031zm78.336-232.96c0-5.1211-4.0977-9.2148-8.7031-9.2148h-32.258c-5.1211 0-8.7031 4.0977-8.7031 9.2148l3.0703 233.47c0 4.6094 4.0977 8.7031 8.7031 8.7031h25.602c4.6094 0 8.7031-4.0977 8.7031-8.7031zm84.988 0.51172c0.51172-5.1211-3.5859-9.2148-8.7031-9.2148h-32.258c-4.6094 0-8.7031 3.5859-8.7031 8.7031l-7.168 232.96c0 5.1211 4.0977 9.2148 8.7031 9.2148h25.602c4.6094 0 8.7031-3.5859 8.7031-8.1914z"/>
</svg>
    )
}

export function AddLink({iconClass}){
    return(
        <svg width="512pt" height="512pt" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={"editorIcon "+iconClass}>
 <g>
  <path d="m412.16 234.09-1.125-1.4336c-7.5352-9.4375-11.648-21.152-11.676-33.227v-34.152c-0.027344-11.996-3.5664-23.723-10.184-33.73-6.6133-10.008-16.012-17.859-27.039-22.59l-29.848-12.801-16.129 37.48 29.848 12.801c3.6836 1.582 6.8203 4.2109 9.0234 7.5586 2.2031 3.3516 3.375 7.2734 3.3672 11.281v34.152c0.035156 20.402 6.6602 40.246 18.895 56.574-12.234 16.328-18.863 36.172-18.895 56.574v34.152c0.007812 4.0078-1.1641 7.9297-3.3672 11.281-2.2031 3.3477-5.3398 5.9766-9.0234 7.5586l-29.848 12.801 16.129 37.633 29.848-12.801c11.027-4.7266 20.426-12.582 27.039-22.59 6.6172-10.008 10.156-21.734 10.184-33.73v-34.305c0.015625-12.094 4.1289-23.824 11.676-33.277l1.125-1.3828c4.9766-6.2188 7.6875-13.949 7.6875-21.914s-2.7109-15.695-7.6875-21.914z"/>
  <path d="m153.6 346.73v-34.152c-0.03125-20.402-6.6602-40.246-18.895-56.574 12.234-16.328 18.863-36.172 18.895-56.574v-34.152c-0.007812-4.0078 1.1641-7.9297 3.3672-11.281 2.2031-3.3477 5.3398-5.9766 9.0234-7.5586l29.848-12.801-16.129-37.633-29.848 12.801c-11.027 4.7266-20.426 12.582-27.039 22.59-6.6172 10.008-10.156 21.734-10.184 33.73v34.152-0.003907c-0.015625 12.094-4.1289 23.828-11.676 33.281l-1.125 1.3828c-4.9766 6.2188-7.6875 13.945-7.6875 21.914 0 7.9648 2.7109 15.691 7.6875 21.914l1.125 1.4336c7.5664 9.4805 11.684 21.25 11.676 33.379v34.152c0.027344 11.996 3.5664 23.723 10.184 33.73 6.6133 10.008 16.012 17.859 27.039 22.59l29.848 12.801 16.129-37.633-29.848-12.801c-3.6602-1.5742-6.7812-4.1797-8.9805-7.4961-2.2031-3.3203-3.3867-7.2109-3.4102-11.191z"/>
  <path d="m322.56 276.48v-40.961h-46.078v-46.078h-40.961v46.078h-46.078v40.961h46.078v46.078h40.961v-46.078z"/>
 </g>
</svg>

    );
}